import {Toast} from "primereact/toast";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ButtonSm} from "../../componentes/ButtonSm";
import {strings} from "../../utils/strings";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import NavBar from "../../componentes/Navbar";
import TipoParecerService from "../../app/service/TipoParecerService";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {coresTipoParecer} from "../../utils/ConstsParecer";
import {CadastrarTipoParecerDialog} from "../../componentes/TipoParecerComponents/CadastrarTipoParecerDialog";
import {Button} from "primereact/button";
import {AlterarTipoParecerDialog} from "../../componentes/TipoParecerComponents/AlterarTipoParecerDialog";
import {confirmDialog} from "primereact/confirmdialog";

export const TipoParecer = () => {

    const [tiposPareceres, setTipoPareceres] = useState([]);

    const [dialogCadastrar, setDialogCadastrar] = useState(false);
    const [dialogAlterar, setDialoAlterar] = useState(false);
    const [tipoParecerAlterar, setTipoParecerAlterar] = useState(null);

    const tipoParecerService = new TipoParecerService()
    const {usuarioAutenticado,setLoading } = useContext(AuthContext)

    const toast = useRef(null)

    function carregarTipoParecer() {
        setLoading(true)
        const codigoEmpresa = usuarioAutenticado.empresa.codigo
        tipoParecerService.buscarTipoParecerPorEmpresa(codigoEmpresa).then((res) => {
            setTipoPareceres(res.data)
        })
            .catch(()=>{
                toast.current.show({
                    severity: 'error',
                    summary: 'Tipo parecer',
                    detail: `Erro ao consutar tipo parecer`,
                    life: 4000
                })
            })
            .finally(()=>{
                setLoading(false)
            })
    }

    function editar(item){
        setTipoParecerAlterar(item)
        setDialoAlterar(true)
    }
  function  confirmaExclusao(tipoParecer) {
        let codigo = tipoParecer.codigo
        confirmDialog({
            message: 'Deseja realmente excluir a garantia de processo?',
            header: 'Exclusão de garantia de processo',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: ()=>accept(codigo),
        });
    }
   function accept(codigo) {
        setLoading(true)
        tipoParecerService.deltearTipo(codigo) .then((res)=>{
            toast.current.show({
                severity: 'success',
                summary: 'Tipo parecer',
                detail: `${res.data}`,
                life: 4000
            })
            carregarTipoParecer()
        })
            .catch(()=>{
                toast.current.show({
                    severity: 'error',
                    summary: 'Tipo parecer',
                    detail: `Erro ao Deletar tipo parecer`,
                    life: 4000
                })
            })
            .finally(()=>{
                setLoading(false)
            })
    }

    useEffect(carregarTipoParecer,[])

    const acoes=(rowData)=> {
        return (
            <div className="row gap-1">
                <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                        onClick={() => editar(rowData)}
                        tooltip="Editar Tipo Parecer"
                        tooltipOptions={{position: 'top'}}/>
                <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                        onClick={() => confirmaExclusao(rowData)}
                        tooltip="Excluir Garantia de processo"
                        tooltipOptions={{position: 'top'}}/>
            </div>
        );
    }

    const bodyTipoCor = (data) => {
        return (
            <div
                style={{
                    backgroundColor: `${coresTipoParecer[data.cor] || data.cor}`,
                    width: '20px', // Defina a largura desejada
                    height: '20px', // Defina a altura desejada
                    borderRadius: '50%', // Torna a borda redonda
                    display: 'inline-block' // Para garantir que a div tenha apenas o tamanho do conteúdo
                }}
            ></div>

        )
    }

    return (<>
        <NavBar/>
        <Toast ref={toast}/>
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-file mr-1"></i>
                                        <span>Tipos parecer</span>
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                      onClick={() => setDialogCadastrar(true)}
                                      data-toggle="modal"
                                      data-target="#modal-default">
                            <i className="fas fa-plus"></i>Cadastrar</ButtonSm>
                          </span>
                                        </div>
                                    </div>
                                </div>
                                <DataTable value={tiposPareceres} paginator responsiveLayout="scroll"
                                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                           currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}"
                                           rows={10}>
                                    <Column field="nome" header="Nome"></Column>
                                    <Column field="tempoSla" header="SLA"></Column>
                                    <Column body={bodyTipoCor} header="Cor"></Column>
                                    <Column key="codigo"></Column>
                                    <Column body={acoes} exportable={false} style={{minWidth: '8rem'}}
                                            header="Ações"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        {dialogCadastrar && <CadastrarTipoParecerDialog
            open={dialogCadastrar}
            close={()=>{setDialogCadastrar(false)}}
            toast={toast.current}
            reloadData={carregarTipoParecer}
        />}
        {dialogAlterar && <AlterarTipoParecerDialog
            tipoParecer={tipoParecerAlterar}
            open={dialogAlterar}
            close={()=>{setDialoAlterar(false)}}
            toast={toast.current}
            reloadData={carregarTipoParecer}
        />}
    </>)
}
export default TipoParecer
