import React from 'react'
import {withRouter} from 'react-router-dom'

import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import {Toast} from 'primereact/toast';
import {InputMask} from 'primereact/inputmask';
import {Button} from 'primereact/button';
import {Message} from 'primereact/message';

import EstadoService from '../../app/service/EstadoService'
import CidadeService from '../../app/service/CidadeService'
import EmpresaService from '../../app/service/EmpresaService'
import {AuthContext} from '../../main/ProvedorAutenticacao'
import ReCAPTCHA from "react-google-recaptcha";
// import * as Yup from 'yup';
import './usuarios.css'
import {IsPasswordEquals, IsValidPassword} from "../../utils/Validator";
import {Password} from "primereact/password";
import {strings} from "../../utils/strings";

class Cadastrar extends React.Component {

    constructor(props) {
        super(props);

        this.estadoService = new EstadoService();
        this.cidadeService = new CidadeService();
        this.empresaService = new EmpresaService();
        this.onEstadoChange = this.onEstadoChange.bind(this);
        this.onCidadeChange = this.onCidadeChange.bind(this);

        this.state = {
            //Cadastro de Usuário
            nome: '',
            email: '',
            cnpj: '',
            razaoSocial: '',
            telefone1: '',
            estados: [],
            cidades: [],
            perfil: { codigo: '1' },
            selectEstado: null,
            selectCidade: null,
            // perfis: [],
            senha: '',
            repitaSenha: '',
            captcha: '',
            isValidPassword: false,
            isValidRepeatPassword: false,
        };
    }


    onStateChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value })
    }
    listarEstados = async () => {
        await this.estadoService.listarEstados()
            .then(response => {
                this.setState({ estados: response.data });
            })
    }

    cadastrarEmpresa = async () => {
        let telefone = this.state.telefone1.replace(/\D/g, '');
        let empresa = {
            nomeUsuario: this.state.nome,
            email: this.state.email,
            nomeEmpresa: this.state.razaoSocial,
            telefone: telefone,
            cnpj: this.state.cnpj,
            cidade: this.state.selectCidade,
            senha: this.state.senha
        }
        this.empresaService.cadastrarNovaEmpresa(empresa)
            .then(async () => {
                this.toast.show({
                    severity: 'success',
                    summary: 'Cadastro de usuários',
                    detail: 'Cadastro Realizado com Sucesso.',
                    life: 4000
                });
                this.limparCampos();
                setTimeout(() => {
                    this.props.history.push('/welcome')
                }, 3000)
            }).catch((error) => {
            this.setState(error.response.data)
            this.toast.show({
                severity: 'error',
                summary: 'Cadastrar empresa',
                detail: `${this.state.detail}`,
                life: 4000
            });
        })
    }

    limparCampos = () => {
        this.setState({
            nome: '',
            email: '',
            cpf: '',
            telefone1: '',
            selectEstado: null,
            cidade: null,
            selectCidade: null,
            senha: '',
            repitaSenha: '',
            cnpj: '',
            razaoSocial: '',
            isValidPassword: false,
            isValidRepeatPassword: false,
            mostrarInstrucoes: false
        })
    }

    cadastrarEmpresaVerificaCampos = async (e) => {
        e.preventDefault();

        if (this.isNullOrBlank(this.state.nome)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o nome do usuário',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.email)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o e-mail do usuário',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.razaoSocial)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a razão social',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.cnpj)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o CNPJ',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.selectEstado)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o estado',
                life: 4000
            });
            return false;
        }
        if (this.state.selectCidade == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.selectCidade)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a cidade',
                life: 4000
            });
            return false;
        }

        if (this.state.perfil == null) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe o perfil do usuário',
                life: 4000
            });
            return false;
        }
        if (this.state.telefone1 === '') {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe um telefone',
                life: 4000
            });
            return false;
        }

        if (this.isNullOrBlank(this.state.senha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe a senha',
                life: 4000
            });
            return false;
        }
        if (this.isNullOrBlank(this.state.repitaSenha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Informe confirmar senha',
                life: 4000
            });
            return false;
        }
        if (this.state.repitaSenha !== this.state.senha) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'As senhas não coincidem',
                life: 4000
            });
            return false;
        }

        if (!IsValidPassword(this.state.senha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Senha inválida',
                life: 4000
            });
            return false;
        }


        if (this.isNullOrBlank(this.state.captcha)) {
            this.toast.show({
                severity: 'error',
                summary: 'Cadastro de usuários',
                detail: 'Verificação não foi resolvida',
                life: 4000
            });
            return false;
        }

        //cadastra empresa logo apos cadastra o usuario
        await this.cadastrarEmpresa()

    }

    voltar = () => {
        this.props.history.push('/login')
    }

    async onEstadoChange(e) {
        await this.setState({ selectEstado: e.value });
        this.listarCidades();
    }

    onCidadeChange(e) {
        this.setState({ selectCidade: e.value })
    }

    listarCidades = async () => {
        if (this.state.selectEstado) {
            await this.cidadeService.listarCidades(this.state.selectEstado.codigo)
                .then(response => {
                    this.setState({ cidades: response.data })
                }).catch(error => {
                    this.setState(error.response.data)
                    this.toast.show({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: `${this.state.detail}`,
                        life: 4000
                    });
                })
        }
    }

    isNullOrBlank = (item) => {
        if (item == null) {
            return true
        }
        return item === '';
    }

    componentDidMount() {
        this.listarEstados();
    }
//verificando  se senha está de acorodo com o sistema
    handlePasswordInput = e => {
        // Set the value of the password to the current value of the input

        this.setState({ senha: e.target.value });
        // Check that the password is at least 8 characters long
        if (IsValidPassword(e.target.value)) {
            this.setState({ isValidPassword: true })
        } else {
            this.setState({ isValidPassword: false })
        }
    }
    handleRepeatPasswordInput = e => {
        // Set the value of the password to the current value of the input

        this.setState({ repitaSenha: e.target.value });
        // Check that the password is at least 8 characters long
        if (IsPasswordEquals(e.target.value, this.state.senha)) {
            this.setState({ isValidRepeatPassword: true })
        } else {
            this.setState({ isValidRepeatPassword: false })
        }
    }

    onPasswordFocusEnd = () => {
        const { senha } = this.state
        if (!IsValidPassword(senha) && senha.length > 0) {
            ///add instruçoes para criar senha
            this.setState({ mostrarInstrucoes: true })
        } else {
            this.setState({ mostrarInstrucoes: false })

        }
    }
    onPasswordRepeatFocusEnd = () => {
        const { senha, repitaSenha } = this.state
        if (!IsPasswordEquals(senha, repitaSenha) && repitaSenha.length > 0) {

        }
    }

    stringsIntrucoes = () => {
        return <div className={'flex-column'}>
            <span>Senha inválida! Insira uma senha seguindo as intruções abaixo</span>
            <span >uma letra maiúscula e uma minúscula (A-Z, a-z) </span>
            <span >um número (0-9)</span>
            <span >e  no minimo 8 (oito) caracteres</span>
        </div>
    }

    alerts = (msg) => {
        return <div className={'mt-1'}><Message severity="warn" text={msg} /></div>
    }
    alertsucess = (msg) => {
        return <div className={'mt-1'}><Message severity="success" text={msg} /></div>
    }

    render() {
        return (
            <>
                <Toast ref={(el) => this.toast = el} />
                <div className="limiter">
                    <form className="container-cad" onSubmit={this.cadastrarEmpresaVerificaCampos}>
                        <section className="content-header-cad">
                            <div className="card card-primary card-outline">
                                <div className="card-header header-align">
                                    <h3 className="card-title">
                                        <i className="fas fa-users"></i>
                                        <span> Cadastrar-se </span>
                                    </h3>
                                </div>
                                <div className='card-body-cad' style={{height:"153vh"}}>
                                    <div className="card-body card-form-cad">
                                        <div className="card">
                                            <div className="card-header font-weight-bold">
                                                Empresa
                                            </div>
                                            <div className="card-body">
                                                <div className="row-etrium" style={{ gap: '5px',marginBottom:"0" }}>
                                                    <div className="group">
                                                        <label>CNPJ<span className="obrigatorioAsterisco"> *</span></label>
                                                        <InputMask mask="99.999.999/9999-99"
                                                                   id="cpnj"
                                                                   name='cnpj'
                                                                   type="text"
                                                                   style={{ width: '100%' }}
                                                                   value={this.state.cnpj}
                                                                   onChange={this.onStateChange}
                                                                   className="p-inputtext-sm p-d-block p-mb-1" />
                                                    </div>
                                                    <div className="group">
                                                        <label>Razão social<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <InputText id="razaoSocial"
                                                                   name="razaoSocial"
                                                                   type="text"
                                                                   style={{ width: '100%' }} autoComplete="off"
                                                                   value={this.state.razaoSocial}
                                                                   onChange={this.onStateChange}
                                                                   className="p-inputtext-sm p-d-block p-mb-1" />

                                                    </div>
                                                </div>
                                                <div className="row-etrium" style={{ gap: '5px',marginBottom:"0" }}>
                                                    <div className="form-group" style={{ flex: 1 }}>
                                                        <label>Estado<span className="obrigatorioAsterisco"> *</span></label>
                                                        <Dropdown value={this.state.selectEstado}
                                                                  options={this.state.estados} style={{ width: '100%' }}
                                                                  optionLabel="estado"
                                                                  filter showClear filterBy="estado"
                                                                  onChange={this.onEstadoChange}
                                                                  id="estado"
                                                                  className="p-inputtext-sm p-d-block p-mb-1" />
                                                    </div>


                                                    {!this.isNullOrBlank(this.state.selectEstado) &&

                                                        <div className="form-group" style={{ flex: 1 }}>
                                                            <label>Cidade<span
                                                                className="obrigatorioAsterisco"> *</span></label>
                                                            <Dropdown value={this.state.selectCidade}
                                                                      options={this.state.cidades}
                                                                      style={{ width: '100%' }}
                                                                      optionLabel="nome"
                                                                      emptyMessage="Nenhuma cidade para este estado"
                                                                      filter showClear filterBy="nome"
                                                                      onChange={this.onCidadeChange}
                                                                      id="cidade"
                                                                      className="p-inputtext-sm p-d-block p-mb-1" />
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header font-weight-bold">
                                                Usuário
                                            </div>
                                            <div className="card-body">
                                                <div className="row-etrium">
                                                    <div className="input-group mb-3">
                                                        <label>Nome<span className="obrigatorioAsterisco"> *</span></label>
                                                        <InputText id="nome"
                                                                   autoFocus={true}
                                                                   type="text"
                                                                   style={{ width: '100%' }}
                                                                   value={this.state.nome}
                                                                   onChange={e => this.setState({ nome: e.target.value })}
                                                                   className="p-inputtext-sm p-d-block p-mb-1" />
                                                    </div>

                                                </div>
                                                <div className="row-etrium" style={{ gap: '5px' }}>

                                                    <div className="group">
                                                        <label>E-mail<span className="obrigatorioAsterisco"> *</span></label>
                                                        <InputText id="email"
                                                                   type="text"
                                                                   style={{ width: '100%' }} autoComplete="off"
                                                                   value={this.state.email}
                                                                   onChange={e => this.setState({ email: e.target.value })}
                                                                   className="p-inputtext-sm p-d-block p-mb-1" />

                                                    </div>

                                                    <div className="group" style={{ width: '100%' }}>
                                                        <label>Telefone<span className="obrigatorioAsterisco"> *</span></label>
                                                        <InputMask mask="(99)9 9999-9999"
                                                                   id="telefone1"
                                                                   name="telefone1"
                                                                   type="text"
                                                                   style={{ width: '100%' }}
                                                                   value={this.state.telefone1}
                                                                   onChange={this.onStateChange}
                                                                   className="p-inputtext-sm p-d-block p-mb-1" />
                                                    </div>

                                                </div>

                                                <div className={'row-etrium'}>
                                                    <div className="group">
                                                        <label>Senha<span className="obrigatorioAsterisco"> *</span></label>
                                                        <Password
                                                            inputStyle={{ width: '100%', flex: 1 }}
                                                            autoComplete="nope"
                                                            id="senha"
                                                            name="senha"
                                                            toggleMask
                                                            feedback={false}

                                                            style={{
                                                                width: "100%",
                                                                borderColor: this.state.isValidPassword ? 'green' : ''
                                                            }}
                                                            value={this.state.senha}
                                                            onBlur={this.onPasswordFocusEnd} //quando elemento sai de foco
                                                            onChange={this.handlePasswordInput}
                                                            className="p-inputtext-sm p-d-block p-mb-1" />
                                                        {this.state.isValidPassword &&
                                                            this.alertsucess("Senha válida!")
                                                        }
                                                        {this.state.senha.length > 0 && !this.state.isValidPassword &&
                                                            this.alerts(this.stringsIntrucoes)
                                                        }

                                                    </div>

                                                </div>

                                                <div className={'row-etrium'}>
                                                    <div className="group" style={{ flex: 1,marginBottom:"0" }}>
                                                        <label>Repita a senha<span
                                                            className="obrigatorioAsterisco"> *</span></label>
                                                        <Password
                                                            inputStyle={{ width: '100%', flex: 1 }}
                                                            autoComplete="nope"
                                                            id="repitaSenha"
                                                            name="repitaSenha"
                                                            toggleMask
                                                            feedback={false}
                                                            style={{
                                                                width: '100%',
                                                                borderColor: this.state.isValidPassword ? 'green' : ''
                                                            }}
                                                            value={this.state.repitaSenha}
                                                            onBlur={this.onPasswordRepeatFocusEnd} //quando elemento sai de foco
                                                            onChange={this.handleRepeatPasswordInput}
                                                            className="p-inputtext-sm p-d-block p-mb-1" />
                                                        {this.state.repitaSenha.length > 0 && this.state.isValidRepeatPassword &&
                                                            this.alertsucess("Senhas iguais!")
                                                        }{this.state.repitaSenha.length > 0 && !this.state.isValidRepeatPassword &&
                                                        this.alerts("As senhas são diferentes!")
                                                    }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-4 mt-2'>
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                name='captcha'
                                                onChange={(value) => this.setState({ captcha: value })} />
                                        </div>
                                        <div className='row-etrium gap-1'>
                                            <Button label="Cadastrar"
                                                    type={'submit'}
                                                    className={strings.buttonPrimaryStyle}
                                                    onClick={this.cadastrarEmpresaVerificaCampos} />
                                            <Button label="Limpar"
                                                    type={'reset'}
                                                    className={strings.buttonWarningStyle}
                                                    onClick={this.limparCampos} />
                                            <Button label="Voltar"
                                                    type={'button'}
                                                    className={strings.buttonSecondaryStyle}
                                                    onClick={this.voltar} />
                                        </div>
                                    </div>

                                    {/*fim inputs*/}

                                </div>
                            </div>
                        </section>
                    </form>
                </div>
            </>
        );
    }
}

Cadastrar.contextType = AuthContext;
export default withRouter(Cadastrar);
