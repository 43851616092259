import ApiService from '../apiservice'

export default class PushService extends ApiService {
  constructor() {
    super('/push')
  }

  quantidadePush(empresa,dataCarregamento) {
    return this.get(`/quantidadePush?empresa=${empresa}&dataCarregamento=${dataCarregamento}`);
  }

  consultarMovimentos(filtro) {
    let params = `/movimentos?empresa=${filtro.empresa}`;
    
    if(filtro.dataInicial != null && filtro.dataFinal != null) {
      params = `${params}&dataInicial=${filtro.dataInicial}&dataFinal=${filtro.dataFinal}`;
    }

    if(filtro.dataCarregamento != null) {
      params = `${params}&dataCarregamento=${filtro.dataCarregamento}`;
    }

    if(filtro.grupos != null) {
      params = `${params}&grupos=${filtro.grupos}`;
    }

    if(filtro.status != null) {
      params = `${params}&status=${filtro.status}`;
    }

    if(filtro.processo != null) {
      params = `${params}&processo=${filtro.processo}`;
    }

    if(filtro.pasta != null) {
      params = `${params}&pasta=${filtro.pasta}`;
    }

    if(filtro.movimentacao != null) {
      params = `${params}&movimentacao=${filtro.movimentacao}`;
    }

    params = `${params}&classificacao=${filtro.classificacao}&ordem=${filtro.ordem}`;

    return this.get(params);
  }

  atualizaMovimento(movimento) {
    return this.put(`/${movimento.codigo}`,movimento);
  }
}