import React from 'react'
import { withRouter } from 'react-router-dom'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { ButtonSm } from '../../componentes/ButtonSm';

import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'

import TipoPagamentoService from '../../app/service/TipoPagamentoService'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";

class TiposPagamentos extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //Cadastro
      codigo: '',
      nome: '',
      empresa: null,
      tiposPagamentos: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      //Progresso
      classeDiv: 'hide',
        loading: true,
    };

    this.tiposPagamentoService = new TipoPagamentoService();

    this.accept = this.accept.bind(this);
    this.acoes = this.acoes.bind(this);
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: ''});
  }

  accept() {
    this.excluirTipoPagamento();
  }

  excluirTipoPagamento = async () => {
    this.setState({classeDiv: 'show'});
    await this.tiposPagamentoService.excluirTipoPagamento(this.state.codigo)
      .then(response => {
        this.toast.show({severity:'success', summary: 'Tipos de pagamentos', detail:'Tipo de pagamento excluído com sucesso', life: 4000});
        this.listarTiposPagamentos();
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  confirmaExclusao(tipo) {
    this.setState({codigo: tipo.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir o tipo de pagamento?',
        header: 'Exclusão de tipo de pagamento',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  listarTiposPagamentos = () => {
    const usuarioLogado = this.context.usuarioAutenticado
    this.tiposPagamentoService.listarTiposPagamentos(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({tiposPagamentos: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
        this.setState({loading:false});
    })
  }

  componentDidMount() {
    this.listarTiposPagamentos();
  }

  cadastrarTipoPagamento = () => {
    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:'Informe o tipo de pagamento', life: 4000});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado

    this.tiposPagamentoService.cadastrarTipoPagamento({
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Tipos de pagamentos', detail:'Tipo de pagamento cadastrado com sucesso', life: 4000});
      this.fecharModalCadastro('modalCadastro');
      this.listarTiposPagamentos();
    }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:`${this.state.detail}`, life: 4000});
    })
  }

  alterarTipoPagamento = async () => {
    this.setState({classeDiv: 'show'});
    if(this.state.nome === '') {
      this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:'Informe o tipo de pagamento', life: 4000});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado

    await this.tiposPagamentoService.alterarTipoPagamento({
      codigo: this.state.codigo,
      nome: this.state.nome,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Tipos de pagamentos', detail:'Tipo de pagamento alterado com sucesso', life: 4000});
      this.fecharModalCadastro('modalAlterar');
      this.listarTiposPagamentos();
    }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({classeDiv: 'hide'});
  }

  acoes(rowData) {
    return(
      <React.Fragment className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar tipo de pagamento"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir tipo de pagamento"
                tooltipOptions={{position: 'top'}}/>
      </React.Fragment>
    );
  }

  async editar(tipo) {
    this.setState({classeDiv: 'show'});
    if(tipo != null) {
      this.tiposPagamentoService.consultarTipoPagamento(tipo.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo});
          this.setState({nome: response.data.nome});
          this.abrirModaAlterar('modalAlterar');
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({severity:'error', summary: 'Tipos de pagamentos', detail:`${this.state.detail}`, life: 4000});
        })
    }
    this.setState({classeDiv: 'hide'});
  }

  renderFooterCadastroTipos(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarTipoPagamento}/>
     </div>
    );
  }

  renderFooterAlterarTipos(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarTipoPagamento}/>
        </div>
    );
  }

  render() {
    return(
      <>
          <Loading open={this.state.loading}/>
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Tipo de Pagamento */}
          <Dialog header="Cadastro de tipo de pagamento"
                    visible={this.state.modalCadastro}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterCadastroTipos('modalCadastro')}
                    onHide={() => this.fecharModalCadastro('modalCadastro')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                   type="text"
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          {/**Alterar Tipo de Pagamento */}
          <Dialog header="Alterar tipo de pagamento"
                    visible={this.state.modalAlterar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarTipos('modalAlterar')}
                    onHide={() => this.fecharModalCadastro('modalAlterar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeTipo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeTipo"
                                   type="text"
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirTipoPagamento}
                         reject={() => this.setState({visible: false})}/>
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-cash-register"></i>
                        <span> Tipos de pagamentos</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    onClick={() => this.abrirModalCadastro('modalCadastro')}
                                    data-toggle="modal"
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                          </span>
                        </div>
                      </div>
                    <Progresso classeSecundaria={this.state.classeDiv}/>
                    </div>
                    <DataTable value={this.state.tiposPagamentos}  paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                      <Column field="nome" header="Nome"></Column>
                      <Column key="codigo"></Column>
                      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

TiposPagamentos.contextType = AuthContext;
export default withRouter(TiposPagamentos);
