import React, {useEffect, useState} from 'react';
import './tourComponent.css';
import {useLocation} from 'react-router-dom';
import UsuarioService from '../../app/service/UsuarioService'
import {strings} from "../../utils/strings";

import {
  stepsAgenda,
  stepsCadProcesso,
  stepsFinanceiro,
  stepsGenerico,
  stepsGT,
  stepsHome,
  stepsPainelAtividades,
  stepsPainelProcesso,
  stepsParecer,
  stepsParecerDetalhe,
  stepsPessoas,
  stepsPessoasPesquisa,
  stepsProcessoeCasos,
  stepsProcessoExcluidos,
  stepsRelatoriosFinanceiro,
  stepsRelatoriosProcesso,
  stepsSuporte,
  stepsUsuarios
} from '../../utils/onboardingSteps';
import LocalStorageService from "../../app/service/localStorageService";
import { Button } from 'primereact/button';

const TourComponent = ({ showTourProp, toggleTour, user }) => {
  const location = useLocation();
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [positionItem, setPositionItem] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [stepIndex, setStepIndex] = useState(0);
  const [showTour, setShowTour] = useState(false);
  const [steps, setSteps] = useState([]);
  const usuarioService = new UsuarioService();
  const [modalFirstAccess, setModalFirstAccess] = useState(false);

  useEffect(() => {

      setShowTour(showTourProp)
    
  }, [showTourProp]);

  

  const stepsMap = {
    '/home': stepsHome,
    '/agenda': stepsAgenda,
    '/cadastrarProcesso': stepsCadProcesso,
    '/processos': stepsProcessoeCasos,
    '/processosExcluidos': stepsProcessoExcluidos,
    '/cadastrarPessoa': stepsPessoas,
    '/pessoas': stepsPessoasPesquisa,
    '/painelFinanceiro': stepsFinanceiro,
    '/relatorioProcessos': stepsRelatoriosProcesso,
    '/relatoriosFinanceiro': stepsRelatoriosFinanceiro,
    '/graficosAtividades': stepsPainelAtividades,
    '/painelProcesso': stepsPainelProcesso,
    '/usuarios': stepsUsuarios,
    '/grupoTrabalho': stepsGT,
    '/suporteUsuario': stepsSuporte,
    '/parecer': stepsParecer,
    '/parecer/detalhe/[0-9]+': stepsParecerDetalhe,
  };
  
  useEffect(() => {
    const defaultSteps = stepsGenerico;
    let steps = defaultSteps;
  
    Object.keys(stepsMap).forEach(route => {
      if (new RegExp(`^${route}$`).test(location.pathname)) {
        steps = stepsMap[route];
      }
    });
  
    if (steps === defaultSteps && !stepsGenerico.includes(steps)) {
      steps = stepsGenerico;
    }
  
    setSteps(steps);
    if(location.pathname ==="/home"){
      isFirstAccess()
    }
  }, [location.pathname]);

  useEffect(() => {
    const tourCompleted = LocalStorageService.obterConsulta("tourCompleted");
  
    if (!tourCompleted) {
      const fetchLogsUsuario = () => {
        usuarioService.verificarPrimeiroAcesso({ usuario: user.codigo })
          .then(response => {
            if (response.data < 2) {
              setShowTour(true);
            }
          })
          .catch(error => {
            console.error('Erro ao obter logs do usuário:', error);
          });
      };
  
      fetchLogsUsuario();
    }
  }, []);
  
  
  const updatePosition = () => {
    const currentStep = steps[stepIndex];
    if (currentStep) {
      const targetElement = document.querySelector(currentStep.selector);
      if (targetElement) {
        const { top, left, width, height } = targetElement.getBoundingClientRect();
        const customStyle = currentStep.style || {};
        const customScroll = currentStep.scrollItem || -110; 
        setPositionItem({ top, left, width, height });
        window.scrollTo(top, top + customScroll);
        setPosition({ top, left, width, height, ...customStyle });
      }
    }
  };

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const handleNextStep = () => {
    if (isLastStep) {
      toggleTour();
      setStepIndex(0);
      
    } else {
      setStepIndex(prevIndex => prevIndex + 1);
      handleScroll();
    }
  };
  
  

  const handlePrevStep = () => {
    setStepIndex(prevIndex => prevIndex - 1);
    handleScroll()
  };

  const handleSkipTour = () => {
    setShowTour(false);
  };

  const handleCloseTour = () => {
      toggleTour();
  };

  const handleReturnToTutorial = () => {
    setShowTour(true); // Mostra o tutorial normal novamente
  };

  const handleEndTour = () => {
    setShowTour(false);
    toggleTour();
    LocalStorageService.adicionarItem("tourCompleted", true); // ou use qualquer método para salvar o estado de tour completo
  };
  

  const isFirstAccess = () => {
    const data = LocalStorageService.obterConsulta("firstAccess")

    if(data === null){
      usuarioService.verificarPrimeiroAcesso({ usuario: user.codigo })
          .then(response => {
            if (response.data < 2) {
             setModalFirstAccess(true)
            }
          })
          .catch(error => {
            console.error('Erro ao obter logs do usuário:', error);
          });
    }
  }

  useEffect(() => {  
    
    updatePosition();
  
    window.addEventListener('resize', updatePosition);
    return () => {
      window.removeEventListener('resize', updatePosition);
    };
  }, [stepIndex, steps]);


  const currentStep = steps[stepIndex];
  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === steps.length - 1;
  const isBodySelector = currentStep && currentStep.selector === 'body';
  {/*//modal de boas vindas primeiro acesso*/}
  // if(modalFirstAccess){
  //   return (
  //       <FirstAccessWelcomeDialog open={modalFirstAccess} close={()=>{setModalFirstAccess(false)}}/>
  //   )
  // }else

  return (
    <>
      {currentStep && showTour && (
        <div className="tour-overlay">
          <div>
            {!isBodySelector && (
              <div className="white-block" style={{ top: positionItem.top, left: positionItem.left, width: positionItem.width, height: positionItem.height }}></div>
            )}
            <div id="tour-balloon" className="balloon" style={{ top: position.top, left: position.left, width: position.width, ...currentStep.style }}>
              <div className="content-container">
                {modalFirstAccess && location.pathname === "/home" && stepIndex === 0 ?
                    <div className="content">
                      <div className="flex-col gap-1 mb-5">
                        {/*<div className="my-auto">*/}
                        {/*    /!*<i className="pi pi-exclamation-triangle" style={{fontSize: '2.5rem',}}></i>*!/*/}
                        {/*</div>*/}
                        <p className="mb-0">
                          Bem-vindo(a) ao Etrium! Você está na avaliação gratuita de 7 dias. Explore nossos recursos e
                          descubra como podemos ajudar.
                        </p>
                        <p className="mb-0">
                          Você pode verificar nossos planos <a className={"text-white text-decoration-underline"} href={"#/contratarPlano"}>aqui</a>
                        </p>

                      </div>
                    </div> :
                    <div>
                      <div className="div-title">
                      <span id="tour-title" className="tour-title">{currentStep.title}</span>
                      <button id="tour-close-btn" className="close-btn" onClick={handleCloseTour}>X</button>
                      </div>
                      <hr className="divider"/>
                    <div id="tour-content" className="content">{currentStep.content}</div></div>
                }
                <div className="button-container">
                <Button id="tour-end-btn" label="Encerrar" type={'button'} className={strings.buttonDanger} onClick={handleEndTour} />

                <div className="prev-next-btn">
                <button id="tour-prev-btn" className="prev-btn" onClick={handlePrevStep} disabled={isFirstStep}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path d="M14.59 7.41L13.17 6l-6 6 6 6 1.41-1.41L9.58 12z"/>
                    </svg>
                  </button>

                  <button id="tour-next-btn" className="next-btn" onClick={handleNextStep}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path d="M9.41 16.59L10.83 18 16.83 12 10.83 6 9.41 7.41 12.83 11z"/></svg>
                  </button>
                </div>
                  

                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
  
};

export default TourComponent;
