import React from 'react'
import { withRouter } from 'react-router-dom'

import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Dropdown } from 'primereact/dropdown'

import GruposPessoasService from '../../app/service/GruposPessoasService'
import GrupoPessoasUsuariosService from '../../app/service/GrupoPessoasUsuariosService'
import UsuarioService from '../../app/service/UsuarioService'

import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'
import { AuthContext } from '../../main/ProvedorAutenticacao'
import {strings} from "../../utils/strings";
import { ButtonSm } from '../../componentes/ButtonSm';
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Loading} from "../../componentes/Loading";

class GruposPessoas extends React.Component {

  constructor(props) {
    super(props);

    this.grupoService = new GruposPessoasService();
    this.grupoPessoasUsuariosService = new GrupoPessoasUsuariosService();
    this.usuarioService = new UsuarioService();

    this.renderFooterCadastroGrupos = this.renderFooterCadastroGrupos.bind(this);
    this.acoes = this.acoes.bind(this);
    this.renderFooterAlterarGrupos = this.renderFooterAlterarGrupos.bind(this);
    this.accept = this.accept.bind(this);
    this.onUsuarioChange = this.onUsuarioChange.bind(this);
    this.acoesUsuariosGrupos = this.acoesUsuariosGrupos.bind(this);

    this.state = {
      classeDiv: 'hide',
      //Cadastro
      codigo: '',
      nome: '',
      descricao: '',
      empresa: null,
      gruposPessoas: [],
      usuariosGrupos: [],
      detail: '',
      selectUsuario: null,
      usuarios: [],
      grupo: {},
      //Modal
      modalCadastro: false,
      modalEditar: false,
      modalUsuarios: false,
      visible: false,
      loading: true,
    }
  }

  listarGruposPessoas = () => {
      this.setState({loading:true})

    const usuarioLogado = this.context.usuarioAutenticado

    this.grupoService.listarGrupos(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({gruposPessoas: response.data});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
          this.setState({loading:false});
    })
  }

  listarUsuariosPorGrupoPessoa(grupo) {
    this.grupoPessoasUsuariosService.listarUsuarios(grupo.codigo)
      .then(response => {
        this.setState({usuariosGrupos: response.data});
        this.setState({nome: grupo.nome});
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Usuários do grupo', detail:`${this.state.detail}`, life: 4000});
      })
  }

  listarUsuariosSemGrupos = async (grupo) => {

    const usuarioLogado = this.context.usuarioAutenticado

    const grupoConsulta = {
      empresa: usuarioLogado.empresa.codigo,
      grupo: grupo.codigo
    }

    await this.usuarioService.listarUsuariosSemGrupoPessoas(grupoConsulta)
      .then(response => {
        this.setState({usuarios: response.data});
      }).catch(error => {
        this.toast.show({severity:'error', summary: 'Grupo de pessoas', detail:'Ocorreu um erro ao carregar usuários', life: 4000});
      })
  }

  listarUsuariosGrupo = async (grupo) => {
    await this.grupoPessoasUsuariosService.listarUsuarios(grupo.codigo)
      .then(response => {
        console.log('Usuarios dos Grupos', response.data)
        this.setState({usuariosGrupos: response.data});
      }).catch(error => {
        this.toast.show({severity:'error', summary: 'Grupo de pessoas', detail:'Ocorreu um erro ao carregar usuários do grupo', life: 4000});
      })
  }

  async exibirUsuariosGrupo(grupo) {
    this.setState({classeDiv: 'show',loading:true});
    await this.setState({grupo: grupo});
          this.setState({nome: grupo.nome});
          this.listarUsuariosSemGrupos(grupo);
          this.listarUsuariosGrupo(grupo);
          this.abrirModaAlterar('modalUsuarios');
    this.setState({classeDiv: 'hide',loading:false});
  }

  incluirUsuarioGrupo = () => {
    if(this.state.selectUsuario == null) {
      this.toast.show({severity:'error', summary: 'Grupo de pessoas', detail:'Informe o usuário', life: 4000});
      return false;
    }

    this.grupoPessoasUsuariosService.incluirUsuario({
      usuario: this.state.selectUsuario,
      grupo: this.state.grupo
    }).then(ressponse => {
      this.listarUsuariosGrupo(this.state.grupo);
      this.listarUsuariosSemGrupos(this.state.grupo);
    }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Usuários do grupo', detail:`${this.state.detail}`, life: 4000});
    })
  }

  async onUsuarioChange(e) {
    await this.setState({selectUsuario: e.value});
    this.incluirUsuarioGrupo();
  }

  componentDidMount() {
    this.listarGruposPessoas();
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({nome: '', descricao: ''});
  }

  cadastrarGrupoPessoa = async () => {
    if(IsNullOrEmpty(this.state.nome)) {
      this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:'Informe o nome do grupo', life: 4000});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado
      this.setState({loading:true})
    await this.grupoService.cadastrarGrupoPessoa({
      nome: this.state.nome,
      descricao: this.state.descricao,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Grupos de pessoas', detail:'Grupo de pessoa cadastrado com sucesso', life: 4000});
      this.listarGruposPessoas();
      this.fecharModalCadastro('modalCadastro')
    }).catch(error => {
      this.setState(error.response.data)
      this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:`${this.state.detail}`, life: 4000});
    });
      this.setState({loading:false})
  }

  renderFooterCadastroGrupos(name) {
    return (
        <div className="row-etrium gap-1">
          <Button label="Cadastrar"
                  className={strings.buttonPrimaryStyle}
                  onClick={() => this.cadastrarGrupoPessoa(name)}/>
        </div>
    );
  }

  renderFooterAlterarGrupos(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarGrupoPessoa}/>
         </div>
    );
  }

  renderFooterUsuariosGrupo(name) {
    return (
        <div>
            <Button label="Cancelar"
                    className={strings.buttonSecondaryStyle}
                    onClick={() => this.fecharModalCadastro(name)}/>
        </div>
    );
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar grupo de pessoa"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-users" className="p-button-rounded p-button-text"
                onClick={() => this.exibirUsuariosGrupo(rowData)}
                tooltip="Usuário com permissão ao grupo de pessoas"
                tooltipOptions={{position: 'top'}}/>
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir grupo de pessoa"
                tooltipOptions={{position: 'top'}}/>
      </div>
    )
  }

  acoesUsuariosGrupos(rowData) {
    return(
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-rounded p-button-text"
                onClick={() => this.excluirUsuarioGrupoPessoa(rowData)}
                tooltip="Excluir usuário"
                tooltipOptions={{position: 'top'}}/>
      </React.Fragment>
    )
  }

  excluirUsuarioGrupoPessoa(grupoPessoa) {
      this.setState({loading:true})
    this.grupoPessoasUsuariosService.excluirUsuario(grupoPessoa.codigo)
      .then(response => {
        this.listarUsuariosGrupo(grupoPessoa.grupo);
        this.listarUsuariosSemGrupos(grupoPessoa.grupo);
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:`${this.state.detail}`, life: 4000});
      })
      this.setState({loading:false})
  }

  accept() {
    this.excluirGrupoPessoa();
  }

  excluirGrupoPessoa = async () => {
    this.setState({classeDiv: 'show',loading:true});
    await this.grupoService.excluirGrupo(this.state.codigo)
      .then(response => {
        this.toast.show({severity:'success', summary: 'Grupos de pessoas', detail:'Grupo de pessoa excluído com sucesso', life: 4000});
        this.listarGruposPessoas();
      }).catch(error => {
        this.setState(error.response.data)
        this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide',loading:false});
  }

  confirmaExclusao(grupo) {
      this.setState({codigo: grupo.codigo});
      confirmDialog({
          message: 'Deseja realmente excluir o grupo selecionado?',
          header: 'Exclusão de grupo de pessoas',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Sim',
          rejectLabel: 'Não',
          accept: this.accept,
          reject: this.reject
      });
    }

  async editar(grupo) {
    this.setState({classeDiv: 'show',loading:true})
    if(grupo.codigo) {
      await this.grupoService.consultarGrupo(grupo.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo});
          this.setState({nome: response.data.nome});
          this.setState({descricao: response.data.descricao});
        }).catch(error => {
          this.setState(error.response.data)
          this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:`${this.state.detail}`, life: 4000});
          this.setState({classeDiv: 'hide'});
        });

        this.abrirModaAlterar('modalEditar');
        this.setState({classeDiv: 'hide',loading:false});
    }
  }

  alterarGrupoPessoa = () => {
    if(this.state.nome === '') {
      this.toast.show({severity:'error', summary: 'Grupos de pessoas', detail:'Informe o nome do grupo', life: 4000});
      return false;
    }

    const usuarioLogado = this.context.usuarioAutenticado
      this.setState({loading:true})
    this.grupoService.alterarGrupo({
      codigo: this.state.codigo,
      nome: this.state.nome,
      descricao: this.state.descricao,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Grupos de pessoas', detail:'Grupo de pessoa alterado com sucesso', life: 4000});
      this.listarGruposPessoas();
      this.fecharModalCadastro('modalEditar')
    })
      this.setState({loading:false})
  }

  render() {
    return(
      <>
          <Loading open={this.state.loading}/>
          <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Grupos de Trabalho */}
          <Dialog header="Cadastro de grupos de pessoas"
                    visible={this.state.modalCadastro}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterCadastroGrupos('modalCadastro')}
                    onHide={() => this.fecharModalCadastro('modalCadastro')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                   type="text"
                                   value={this.state.nome}
                                   onChange={e => this.setState({nome: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="descricao">Descrição<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="descricao"
                                   type="text"
                                   value={this.state.descricao}
                                   onChange={e => this.setState({descricao: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          {/**Alterar Grupo de Pessoa */}
          <Dialog header="Alterar grupo de pessoa"
                    visible={this.state.modalEditar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarGrupos('modalEditar')}
                    onHide={() => this.fecharModalCadastro('modalEditar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeGrupo">Nome<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeGrupo"
                                  type="text"
                                  value={this.state.nome}
                                  onChange={e => this.setState({nome: e.target.value})}
                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                    <div className="p-field">
                        <label htmlFor="descricao">Descrição<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="descricao"
                                  type="text"
                                  value={this.state.descricao}
                                  onChange={e => this.setState({descricao: e.target.value})}
                                  className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
            </Dialog>
            {/**Consulta do usuários que acessam o grupo */}
            <Dialog header={`Usuários do grupo de pessoas - (${this.state.nome})`}
                    visible={this.state.modalUsuarios}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterUsuariosGrupo('modalUsuarios')}
                    onHide={() => this.fecharModalCadastro('modalUsuarios')}>
                <div class="row">
                    <div class="col-md-8">
                        <div className="p-fluid">
                            <div className="p-field">
                                <label htmlFor="usuarioGrupo">Usuário(Adicionar)</label>
                                <Dropdown value={this.state.selectUsuario} options={this.state.usuarios}
                                        onChange={this.onUsuarioChange}
                                        optionLabel="nome" filter showClear filterBy="nome"
                                        placeholder="Selecione o usuario"
                                        emptyMessage="Nenhum usuário"
                                        className="p-inputtext-sm p-d-block p-mb-1"/>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div className="row">
                    <div class="col-md-12">
                      <DataTable value={this.state.usuariosGrupos}  paginator responsiveLayout="scroll"
                          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                          currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={5}
                          emptyMessage="Nenhum usuário para este grupo">
                          <Column field="usuario.nome" header="Usuário"></Column>
                          <Column body={this.acoesUsuariosGrupos} exportable={false} style={{ minWidth: '8rem' }} header="Ações"></Column>
                      </DataTable>
                    </div>
                </div>
            </Dialog>
            <ConfirmDialog visible={this.state.visible}
                           onHide={() => this.setState({ visible: false })}
                           message="Are you sure you want to proceed?"
                           header="Confirmation"
                           icon="pi pi-exclamation-triangle"
                           accept={() => this.excluirGrupoPessoa}
                           reject={() => this.setState({visible: false})}/>

          <section className="content-header">
              <div className="container-fluid">
              <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary card-outline">
                      <div className="card-header">
                        <h3 className="card-title">
                          <i className="fas fa-people-carry"></i>
                          <span> Grupos de pessoas</span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-5">
                            <span className="input-group">
                              <ButtonSm type="button" className={strings.buttonPrimarySm}
                                      onClick={() => this.abrirModalCadastro('modalCadastro')}
                                      data-toggle="modal"
                                      data-target="#modal-default">
                              <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                            </span>
                          </div>
                        </div>

                      </div>
                      <DataTable value={this.state.gruposPessoas}  paginator responsiveLayout="scroll"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                        <Column field="nome" header="Nome"></Column>
                        <Column field="descricao" header="Descrição"></Column>
                        <Column key="codigo"></Column>
                        <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações"></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
      </>
    );
  }
}

GruposPessoas.contextType = AuthContext;
export default withRouter(GruposPessoas)
