import {Button} from "primereact/button";
import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {strings} from "../utils/strings";
import {Dropdown} from "primereact/dropdown";


export const DialogSelectSystemProcesso = ({open, close, addSystem}) => {

    const sistemasOptions = [
        {id: 1, nome: "TJPB 1 GRAU", fila: "TJPB1"},
        {id: 2, nome: "TJPB 2 GRAU", fila: "TJPB2"},
        {id: 3, nome: "TJPE 1 GRAU", fila: "TJPE1"},
        {id: 4, nome: "TJPE 2 GRAU", fila: "TJPE2"},
    ]

    const [sistema, setSistema] = useState(sistemasOptions[0]);

    function onSubmit(){
        if(sistema.fila !== undefined){
             addSystem(sistema.fila)
        }
        else{
            addSystem(null)
        }
        close()
    }

    function renderFooter() {
        return (
            <div className="flex-row justify-content-start">
                <Button label="Confirmar"
                    className={strings.buttonPrimaryStyle}
                    onClick={onSubmit} />
            </div>
        );
    }
    return (

        <Dialog header="Selecione o sistema a que esse processo pertence"
            visible={open}
            style={{ width: '40vw' }}
            footer={renderFooter()}
            onHide={close}>

            <div className="flex-1 flex-column">
                <label>Sistema</label>
                <Dropdown value={sistema}
                          options={sistemasOptions}
                          style={{width: '100%', height: '37px'}}
                          optionLabel="nome"
                          filter showClear filterBy="nome"
                          onChange={(e) => setSistema(e.value)}
                          id="sistema"
                          className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
            </div>
        </Dialog>

    );
}
