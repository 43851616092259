import ApiService, {httpClient} from '../apiservice'

class UsuarioService extends ApiService {

  constructor() {
    super('/usuarios')
  }

  autenticar(usuario) {
    delete httpClient.defaults.headers.common['Authorization'];
    return this.post('/autenticar',usuario);
  }

  cadastrarUsuario(usuario) {
    return this.post('/cadastrar',usuario);
  }

  listarUsuario(filtroUsuario) {
    const params = `?empresa=${filtroUsuario.empresa}&nome=${filtroUsuario.consulta}`
    return this.get(params);
  }

  consultarUsuario(codigo) {
    return this.get(`/${codigo}`);
  }

  excluirUsuario(codigo) {
    return this.delete(`/${codigo}`);
  }

  alterarUsuario(usuario) {
    return this.put(`/${usuario.codigo}`, usuario);
  }

  listarUsuariosSemGrupo(grupoConsulta) {
    const params = `?empresa=${grupoConsulta.empresa}&grupo=${grupoConsulta.grupo}`
    return this.get(`/usuariosSemGrupo${params}`);
  }

  listarUsuariosSemGrupoPessoas(grupoConsulta) {
    const params = `/usuariosSemGrupoPessoas?empresa=${grupoConsulta.empresa}&grupo=${grupoConsulta.grupo}`
    return this.get(params);
  }

  listarUsuariosEmpresa(empresa) {
    return this.get(`/empresa/${empresa}`);
  }

  listarUsuariosEmpresaFiltrado(usuario) {
    return this.get(`/empresaFiltrado/${usuario}`);
  }

  alterarSenha(alterSenhaVO) {
    // console.log(senha, novaSenha, confirmacao, usuario)
    // return this.put(`/alterarSenha?codigo=${usuario.codigo}&senha=${senha}&novaSenha=${novaSenha}&confirmacao=${confirmacao}`, usuario);
    return this.put("/alterarSenha", alterSenhaVO);
  }

  esqueceuSenha(email){
    return this.get(`/esqueciMinhaSenha/${email}`)
  }
  //esqueceu a senha
  redefinirSenha(token, senha){
    return this.get(`/esqueciMinhaSenha/redefinirSenha?token=${token}&senha=${senha}`)
  }
  getUsersByPerfilParecer(empresa){
    return this.get(`/parecer/perfil/${empresa}`)
  }

  logout(log) {
    return this.post('/log',log);
  }

  verificarPrimeiroAcesso(usuario) {
    const params = `?usuario=${usuario.usuario}`
    return this.get(`/logsUsuario${params}`);
  }

  adicionarUsuarioProcessoAutomatico(processoAutomatico) {
    return this.post('/adicionarUsuarioProcessoAuto',processoAutomatico);
  }
  removerUsuarioProcessoAutomatico(codigoUsuario) {
    return this.delete(`/removerUsuarioProcessoAuto/${codigoUsuario}`);
  }


}

export default UsuarioService
