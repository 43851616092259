export const strings = {
    buttonPrimaryStyle: "p-button-primary p-button-outlined",
    buttonSecondaryStyle: "p-button-secondary p-button-outlined",
    buttonWarningStyle: "p-button-warning p-button-outlined",
    buttonHelp: "p-button-help p-button-outlined",
    buttonPlain: "p-button-plain p-button-outlined",
    buttonDanger: "p-button-danger p-button-outlined",
    buttonInfo: "p-button-info p-button-outlined",
    buttonSuccess: "p-button-success p-button-outlined ",


    //sm
    buttonSecondarySm : "p-button-secondary p-button-outlined p-button-sm",
    buttonSuccessSm : "p-button-success p-button-outlined p-button-sm",
    buttonInfoSm : "p-button-info p-button-outlined p-button-sm",
    buttonPrimarySm : "p-button-primary p-button-outlined p-button-sm",
    buttonDangerSm: "p-button-danger p-button-outlined p-button-sm",

    //buttons pencil
    buttonIconSecundary: "p-button-rounded p-button-text p-button-secondary",

    //buttons trash
    buttonIconDanger: "p-button-rounded p-button-text p-button-danger",
}

export const STRING = {
    PH_PesquisaProcesso: "Pesquise um processo",
    title_remover_usuario_cadastro_auto_processo: "Remover cadastro de processo automático"
}
export const ALERT_MSG_PROCESSO_AUTO = "Ao incluir um usuário no processo automático, você deve obrigatoriamente informar o CPF e a senha do usuário deve ser a mesma dos respectivos tribunais."
export const ALERT_MSG_PROCESSO_AUTO_ALTER = "Ao incluir um usuário no processo automático, certifique-se que o mesmo tenha o campo CPF devidamente preenchido e a senha cadastrada no sistema Etrium deve ser a mesma dos respectivos tribunais."

export const graficosLegendas = {
    atividadeSituacaoGeral:  "Quantidade de atividades por Situação: Atrasadas, Concluídas com Atraso ou no Prazo",
    atividadeSituacaoIndividual:  "Quantidade de atividades do Usuário por Situação: Atrasadas, Concluídas com Atraso ou no Prazo",
    atividadeStatusGeral:  "Quantidade de atividades por Status",
    atividadeStatusIndividual:  "Quantidade de atividades do Usuário por Status",
    atividadeGrupoTrabalho:  "Quantidade de atividades por Grupo de Trabalho",
    atividadesCriadas:  "Quantidade de atividades criadas por mês no período do ano atual. Obs: apenas é possível filtrar por ano, não sendo possível filtrar atividades entre dois anos diferentes.",

    //procesos
    processoStatusProcessual:  "Quantidade de processos por status processual.",
    processosGrupoTrabalho:  "Quantidade de processos por grupo de trabalho.",
    processosAreaAtuacao:  "Quantidade de processos por area de atuaçao.",
    processosObjetos:  "Quantidade de processos com objetos de ação por objeto.",
    processosTipoAcao:  "Quantidade de processos por tipo ação.",
    processosCadastradosArquivados:  "Quantidade de processos cadastrados e arquivados no sistema por mês.",

    //parecer
    parecerProdutividade: "Quantidade de pareceres cadastrados no sistema, com ou sem responsável.",
    parecerEficiencia:  "Quantidade de pareceres sem atraso.",
    parecerEficacia: "Quantidade de pareceres com status finalizado, sem devolução.",


    //financeiro
    financeiroDespesas: "Quantidade de despesas por tipo.",
    financeiroReceitas: "Quantidade de receitas por tipo.",
    financeiroReceitasDespesas: "Quantidade de receitas e despesas por mês.",

    //empresa
    empresaGrafico: "Quantidade de processos e atividades cadastrados da empresa.",
    empresaStatus: "Quantidade de empresas nos respectivos planos.",
}