import React from 'react'
import { withRouter } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';

import UsuarioService from '../../app/service/UsuarioService'
import { AuthContext } from '../../main/ProvedorAutenticacao';

import '../css/util.css'
import './login.css'
import Logo from './img/etrium-logo1.png';
import { strings } from "../../utils/strings";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Loading } from '../../componentes/Loading';
import { RedefinirSenha } from "../../componentes/seguranca/RedefinirSenha";
import { SolicitarUsuarioEmailConfirmDialog } from "../../componentes/seguranca/SolicitarUsuarioEmailConfirmDialog";

const baseUrl = process.env.REACT_APP_BASE_URL;
class Login extends React.Component {

    state = {
        email: '',
        senha: '',
        detail: '',
        classColor: "",
        loading: false,
        modalRedefinirSenha: false,
        modalResendEmail: false,
    }

    constructor(props) {
        super(props);
        this.service = new UsuarioService();
    }

    entrar = async (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        await this.service.autenticar({
            email: this.state.email,
            senha: this.state.senha
        }).then(async (response) => {
            const data = response.data
            await this.context.iniciarSessao(data);
            this.props.history.push('/home');
            window.location.reload();

        }).catch(erro => {
            // console.log(erro.response.data.detail)
            try {
                let detail = erro.response.data.detail
                this.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: detail,
                    life: 4000
                })
                if (detail.toString().includes("Por favor, verifique seu e-mail para acessar o sistema")) {
                    this.setState({ modalResendEmail: true })
                }
            } catch (error) {
                this.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: "Erro inesperado. Tente novamente em alguns instantes",
                    life: 4000
                });
                console.log(erro);
            }

            this.setState({ loading: false })
        })

    }

    handleEsqueciSenha = () => {
        this.setState({ modalRedefinirSenha: true })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.detail.length > 2) {
            setTimeout(() => { this.setState({ detail: "" }) }, 10000)
        }
    }

    isEtrium= ()=>{
        return !!baseUrl.includes("etrium.com.br") || !!baseUrl.includes("localhost")
    }

    render() {
        return (
            <div className="limiter">
                <Toast ref={(el) => this.toast = el} />
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Loading open={this.state.loading} />
                        <div className="logo-container">
                            <img className="logo" src={Logo} alt='Logo Etrium'></img>
                        </div>
                        <form onSubmit={this.entrar}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">@</span>
                                </div>
                                <InputText type="email"
                                    value={this.state.email}
                                    autoComplete="none"
                                    onChange={e => this.setState({ email: e.target.value })}
                                    className="form-control" placeholder="E-mail" />
                            </div>
                            <div>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-unlock-alt"></i></span>
                                    </div>
                                    <InputText type="password"
                                        autoComplete="none"
                                        value={this.state.senha}
                                        onChange={e => this.setState({ senha: e.target.value })}
                                        className="form-control" placeholder="Senha"

                                    />
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                </div>
                                <div className={"flex-row justify-content-end align-items-end"}>
                                    <a className="ml-auto txt1" onClick={this.handleEsqueciSenha} >
                                        Esqueceu a senha?
                                    </a>
                                </div>
                                {this.state.detail.length > 0 && <Message severity={this.state.classColor} text={this.state.detail} />}

                                {/* <span className={this.state.classColor}>{this.state.detail}</span> */}
                            </div>

                            <br />
                            <div className="container-login100-form-btn gap-3 col">
                                <Button type={'submit'} className={strings.buttonPrimaryStyle}
                                >
                                    Entrar
                                </Button>
                            </div>
                        </form>
                        <div className="text-center p-t-10">
                            {this.isEtrium() &&
                                <>
                            <a className="txt1" href={"#/cadastro"}>
                                Cadastrar-se
                            </a><br />
                            </>
                            }
                            <a className="txt1" href={"#/suporte"}>
                                Suporte
                            </a>

                        </div>

                    </div>
                </div>
                {this.state.modalRedefinirSenha && <><RedefinirSenha service={this.service}
                    toast={this.toast}
                    open={this.state.modalRedefinirSenha}
                    onClose={() => this.setState({ modalRedefinirSenha: false })} /></>}
                {this.state.modalResendEmail && <><SolicitarUsuarioEmailConfirmDialog
                    toast={this.toast}
                    open={this.state.modalResendEmail}
                    onClose={() => this.setState({ modalResendEmail: false })} /></>}
            </div>
        )
    }
}

Login.contextType = AuthContext
export default withRouter(Login)
