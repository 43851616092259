import React, {useCallback, useContext, useRef, useState} from "react";
import {AuthContext} from "../../main/ProvedorAutenticacao";
import {Dropdown} from "primereact/dropdown";
import {ButtonEtrium} from "../../componentes/ButtonEtrium";
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {Toast} from "primereact/toast";
import ProcessoAutomaticoService from "../../app/service/ProcessoAutomaticoService";
import {useHistory} from "react-router-dom";
import Navbar from "../../componentes/Navbar";
import {Loading} from "../../componentes/Loading";
import {InputText} from "primereact/inputtext";
import {formatCnj} from "../../utils/nrCnj-format";

export const CadastrarProcessoAutomatico = () => {
    const sistemasOptions = [
        {id: 1, nome: "TJPB 1 GRAU", fila: "tjpb.v1.tjpb1"},
        {id: 2, nome: "TJPB 2 GRAU", fila: "tjpb.v1.tjpb2"},
        {id: 3, nome: "TJPE 1 GRAU", fila: "tjpe.v1.tjpe1"},
        {id: 4, nome: "TJPE 2 GRAU", fila: "tjpe.v1.tjpe2"},
    ]

    const toastRef = useRef(null)

    const [numeroProcesso, setNumeroProcesso] = useState("");
    const [sistema, setSistema] = useState(sistemasOptions[0]);
    const [loading, setLoading] = useState(false);
//context
    const {usuarioAutenticado} = useContext(AuthContext);
//service
    const service = new ProcessoAutomaticoService()

    const navigation = useHistory()

   async function cadastrarProcessoAutomatico() {
        if (verificaCampos()) {
            return
        }
        setLoading(true)
        // montando classe processo
        const processo = {
            usuario: usuarioAutenticado,
            numeroProcesso: numeroProcesso,
            fila: sistema.fila
        }
        // fazer a consulta
      await service.cadastrar(processo).then((res) => {
            const data = res.data
            toastRef.current.show({
                severity: 'success',
                summary: 'Cadastro',
                detail: `${data}`,
                life: 4000
            })
          setNumeroProcesso("")
        }).catch(error=>{
            const detail = error.response.data.detail
            toastRef.current.show({
                severity: 'error',
                summary: 'Cadastro',
                detail: `${detail}`,
                life: 4000
            })
        }).finally(()=>{
        setLoading(false);
      })



    }

    function verificaCampos() {
        if (sistema === undefined) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Selecione um sistema',
                life: 4000
            })
            return true
        }
        if (IsNullOrEmpty(numeroProcesso)) {
            toastRef.current.show({
                severity: 'error',
                summary: 'Campos obrigatórios',
                detail: 'Insira o número do processo',
                life: 4000
            })
            return true
        }
        return false
    }

    function goToProcessoManual() {
        navigation.push("/cadastrarProcessoManual")
    }

    const onInpoutProcessoChange = useCallback((event)=>{
        const {value} = event.target
        setNumeroProcesso(formatCnj.formatarNumeroProcesso(value))
    },[setNumeroProcesso])

    return (
        <>
            <Navbar/>
            <Toast ref={toastRef}/>
            <Loading open={loading} />
            <div className="content-wrapper">
                <div className="container-fluid">
                    <section className="content-header">
                        <div className="card card-primary card-outline">
                            <div className="card-header">
                                <h3 className="card-title">
                                    <i className="nav-icon fas fa-balance-scale"></i>
                                    <span className="ml-1">Cadastrar processo automático</span>
                                </h3>
                            </div>
                            <div className='card-form-align-start'>
                                <div className="card-body card-max-width">
                                    <div className="flex-row gap-5">
                                        <div className="flex-1 flex-column">
                                            <label>Número do processo<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <InputText id="numeroCnj"
                                                       type="text"
                                                       // mask="9999999-99.9999.9.99.9999"
                                                       style={{width: '100%'}}
                                                       value={numeroProcesso}
                                                       onChange={onInpoutProcessoChange}
                                                       maxLength={25}
                                                       className="p-inputtext-sm p-d-block p-mb-1"/>
                                        </div>
                                        <div className="flex-1 flex-column">
                                            <label>Sistema<span
                                                className="obrigatorioAsterisco"> *</span></label>
                                            <Dropdown value={sistema}
                                                      options={sistemasOptions}
                                                      style={{width: '100%', height: '37px'}}
                                                      optionLabel="nome"
                                                      filter showClear filterBy="nome"
                                                      onChange={(e) => setSistema(e.value)}
                                                      id="sistema"
                                                      className="p-inputtext-sm p-d-block p-mb-1 card-max-width"/>
                                        </div>
                                    </div>
                                    <div className="footer-buttons-cadastro gap-1">
                                        <ButtonEtrium buttonType={'primary'}
                                                      children={"Cadastrar"}
                                                      onClick={cadastrarProcessoAutomatico}
                                        />
                                        <ButtonEtrium id='BtnCadProcessoManual' buttonType={'secondary'}
                                                      children={"Cadastrar processo manual"}
                                                      onClick={goToProcessoManual}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );

}
