import React from 'react'
import { withRouter } from 'react-router-dom'

import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import StatusProcessualService from '../../app/service/StatusProcessualService'
import { AuthContext } from '../../main/ProvedorAutenticacao'

import NavBar from '../../componentes/Navbar'
import Progresso from '../../componentes/Progresso'
import {strings} from "../../utils/strings";
import { ButtonSm } from '../../componentes/ButtonSm';
import {IsNullOrEmpty} from "../../utils/IsNullOrEmpy";
import {DialogStatusProcessual} from '../../componentes/DialogStatusProcessual'
import {Loading} from "../../componentes/Loading";

class StatusProcessual extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      //Cadastro
      codigo: '',
      descricao: '',
      empresa: null,
      status: [],
      modalCadastro: false,
      modalAlterar: false,
      visivble: false,
      //Progresso
      classeDiv: 'hide',
      open: false,
      loading: true
    };

    this.statusProcessualService = new StatusProcessualService();
    this.acoes = this.acoes.bind(this);
    this.renderFooterCadastroStatus = this.renderFooterCadastroStatus.bind(this);
    this.renderFooterAlterarStatus = this.renderFooterAlterarStatus.bind(this);
    this.accept = this.accept.bind(this);
  }

  fecharModalCadastro(name) {
    this.setState({
        [`${name}`]: false
    });
  }

  abrirModalCadastro(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }
    this.limparCampos();
    this.setState(state);
  }

  abrirModaAlterar(name, position) {
    let state = {
        [`${name}`]: true
    };

    if (position) {
        state = {
            ...state,
            position
        }
    }

    this.setState(state);
  }

  limparCampos = () => {
    this.setState({descricao: ''});
  }

  accept() {
    this.excluirStatusProcessual();
  }

  cadastrarStatusProcessual = async () => {
    if(IsNullOrEmpty(this.state.descricao)) {
      this.toast.show({severity:'error', summary: 'Status processual', detail:'Informe a descrição do status', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.statusProcessualService.cadastrarStatusProcessual({
      descricao: this.state.descricao,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Status processual', detail:'Status processual cadastrado com sucesso', life: 4000});
      this.listarStatusProcessual();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Status Processual', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({classeDiv: 'hide'});
    this.fecharModalCadastro('modalCadastro');
  }

  listarStatusProcessual = async () => {
    this.setState({classeDiv: 'show'});
    const usuarioLogado = this.context.usuarioAutenticado
    await this.statusProcessualService.listarStatusProcessual(usuarioLogado.empresa.codigo)
      .then(response => {
        this.setState({status: response.data});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Status processual', detail:`${this.state.detail}`, life: 4000});
      }).finally(()=>{
          this.setState({loading:false});
        });

      this.setState({classeDiv: 'hide'});
  }

  renderFooterCadastroStatus(name) {
    return (
        <div className="row-etrium gap-1">
             <Button label="Cadastrar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.cadastrarStatusProcessual}/>
        </div>
    );
  }

  renderFooterAlterarStatus(name) {
    return (
        <div className="row-etrium gap-1">
            <Button label="Alterar"
                    className={strings.buttonPrimaryStyle}
                    onClick={this.alterarStatusProcessual}/>
        </div>
    );
  }

  acoes(rowData) {
    return(
      <div className="row gap-1">
        <Button icon="pi pi-pencil" className={strings.buttonIconSecundary}
                onClick={() => this.editar(rowData)}
                tooltip="Editar status processual"
                tooltipOptions={{position: 'top'}} />
        <Button icon="pi pi-trash" className={strings.buttonIconDanger}
                onClick={() => this.confirmaExclusao(rowData)}
                tooltip="Excluir status processual"
                tooltipOptions={{position: 'top'}}/>
      </div>
    );
  }

  async editar(status) {
    this.setState({classeDiv: 'show'});
    if(status != null) {
      await this.statusProcessualService.consultarStatusProcessual(status.codigo)
        .then(response => {
          this.setState({codigo: response.data.codigo});
          this.setState({descricao: response.data.descricao});
          this.abrirModaAlterar('modalAlterar');
        }).catch(error => {
          this.setState(error.response.data);
          this.toast.show({severity:'error', summary: 'Status processual', detail:`${this.state.detail}`, life: 4000});
        });

        this.setState({classeDiv: 'hide'});
    }
  }

  alterarStatusProcessual = async () => {
    this.setState({classeDiv: 'show'});
    if(this.state.descricao === '') {
      this.toast.show({severity:'error', summary: 'Status processual', detail:'Informe a descrição do status', life: 4000});
      this.setState({classeDiv: 'hide'});
      return false;
    }
    const usuarioLogado = this.context.usuarioAutenticado
    await this.statusProcessualService.alterarStatusProcessual({
      codigo: this.state.codigo,
      descricao: this.state.descricao,
      empresa: usuarioLogado.empresa
    }).then(response => {
      this.toast.show({severity:'success', summary: 'Status processual', detail:'Status processual alterado com sucesso', life: 4000});
      this.listarStatusProcessual();
    }).catch(error => {
      this.setState(error.response.data);
      this.toast.show({severity:'error', summary: 'Status processual', detail:`${this.state.detail}`, life: 4000});
    });

    this.setState({classeDiv: 'hide'});
    this.fecharModalCadastro('modalAlterar');
  }

  confirmaExclusao(status) {
    this.setState({codigo: status.codigo});
    confirmDialog({
        message: 'Deseja realmente excluir o status processual?',
        header: 'Exclusão de status processual',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: this.accept,
        reject: this.reject
    });
  }

  async excluirStatusProcessual() {
    this.setState({classeDiv: 'show'});
    await this.statusProcessualService.excluirStatusProcessual(this.state.codigo)
      .then(response => {
        this.listarStatusProcessual();
        this.toast.show({severity:'success', summary: 'Status processual', detail:'Status Processual excluído com sucesso', life: 4000});
      }).catch(error => {
        this.setState(error.response.data);
        this.toast.show({severity:'error', summary: 'Status Processual', detail:`${this.state.detail}`, life: 4000});
      });

      this.setState({classeDiv: 'hide'});
  }

  componentDidMount() {
    this.listarStatusProcessual();
  }

  handleToggle = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  }

  render() {
    return(
      <>
        <Loading open={this.state.loading} />
        <NavBar/>
        <Toast ref={(el) => this.toast = el} />
        <div className="content-wrapper">
          {/**Cadastro de Status Processual */}
            <DialogStatusProcessual open={this.state.modalCadastro} close={() => this.fecharModalCadastro('modalCadastro')} toast={this.toast} refrashList={() => this.listarStatusProcessual()}/>
          {/*<Dialog header="Cadastro de Status Processual"*/}
          {/*          visible={this.state.modalCadastro}*/}
          {/*          style={{ width: '40vw' }}*/}
          {/*          footer={this.renderFooterCadastroStatus('modalCadastro')}*/}
          {/*          onHide={() => this.fecharModalCadastro('modalCadastro')}>*/}

          {/*      <div className="p-fluid">*/}
          {/*          <div className="p-field">*/}
          {/*              <label htmlFor="nomeStatus">Descrição</label>*/}
          {/*              <InputText id="nomeStatus"*/}
          {/*                         type="text"*/}
          {/*                         value={this.state.descricao}*/}
          {/*                         onChange={e => this.setState({descricao: e.target.value})}*/}
          {/*                         className="p-inputtext-sm p-d-block p-mb-1"/>*/}
          {/*          </div>*/}
          {/*      </div>*/}
          {/*</Dialog>*/}
          {/**Alterar Status Processual */}
          <Dialog header="Alterar status processual"
                    visible={this.state.modalAlterar}
                    style={{ width: '40vw' }}
                    footer={this.renderFooterAlterarStatus('modalAlterar')}
                    onHide={() => this.fecharModalCadastro('modalAlterar')}>

                <div className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="nomeStatusAlterar">Descrição<span className="obrigatorioAsterisco"> *</span></label>
                        <InputText id="nomeStatusAlterar"
                                   type="text"
                                   value={this.state.descricao}
                                   onChange={e => this.setState({descricao: e.target.value})}
                                   className="p-inputtext-sm p-d-block p-mb-1"/>
                    </div>
                </div>
          </Dialog>
          <ConfirmDialog visible={this.state.visible}
                         onHide={() => this.setState({ visible: false })}
                         message="Are you sure you want to proceed?"
                         header="Confirmation"
                         icon="pi pi-exclamation-triangle"
                         accept={() => this.excluirStatusProcessual}
                         reject={() => this.setState({visible: false})}/>
          <section className="content-header">
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary card-outline">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-stream"></i>
                        <span> Status processual</span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-5">
                          <span className="input-group">
                            <ButtonSm type="button" className={strings.buttonPrimarySm}
                                    onClick={() => this.abrirModalCadastro('modalCadastro')}
                                    data-toggle="modal"
                                    data-target="#modal-default">
                            <i className="fas fa-plus"></i> Cadastrar</ButtonSm>
                          </span>
                        </div>
                      </div>

                    </div>
                    <DataTable value={this.state.status}  paginator responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo {first} para {last} de {totalRecords}" rows={10}>
                      <Column field="descricao" header="Nome"></Column>
                      <Column key="codigo"></Column>
                      <Column body={this.acoes} exportable={false} style={{ minWidth: '8rem' }}header="Ações"></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

StatusProcessual.contextType = AuthContext;
export default withRouter(StatusProcessual)
