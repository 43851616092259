import React, {useContext} from 'react'
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom'

import Home from '../pages/home/Home'
import EditarEmpresa from "../pages/empresa/EditarEmpresa";
import Login from '../pages/seguranca/Login'
import GrupoTrabalho from '../../src/pages/gruposTrabalho/Grupos'
import SubGrupos from '../../src/pages/gruposTrabalho/SubGrupos'
import Usuarios from '../../src/pages/usuarios/Usuarios'
import CadastroUsuario from '../../src/pages/usuarios/CadastroUsuario'
import EditarUsuario from '../../src/pages/usuarios/EditarUsuario'
import GruposPessoas from '../../src/pages/gruposPessoas/GruposPessoas'
import TiposPagamentos from '../../src/pages/tiposPagamentos/TiposPagamentos'
import TiposCustas from '../../src/pages/tiposCustas/TiposCustas'
import StatusProcessual from '../../src/pages/statusProcessual/StatusProcessual'
import ObjetosAcao from '../../src/pages/objetosAcao/ObjetosAcao'
import TipoDecisao from '../../src/pages/tiposDecisao/TipoDecisao'
import TipoGarantia from '../../src/pages/tiposGarantias/TipoGarantia'
import Processos from '../../src/pages/processo/Processos'
import CadastrarProcesso from '../../src/pages/processo/CadastrarProcesso'
import FichaProcesso from '../../src/pages/processo/FichaProcesso'
import Pessoas from '../../src/pages/pessoas/Pessoas'
import ConsultarPessoas from '../../src/pages/pessoas/ConsultarPessoa'
import CadastrarPessoa from '../../src/pages/pessoas/cadastrarPessoa'
import TiposDespesasReceitas from '../../src/pages/tiposDespesasReceitas/TiposDespesasReceitas'
import PainelFinanceiro from '../../src/pages/financeiro/PainelFinanceiro'
import RelatorioFinanceiro from '../../src/pages/financeiro/RelatorioFinanceiro'
import Suporte from '../pages/seguranca/Suporte'
import Atendimentos from '../../src/pages/atendimentos/Atendimentos'
import RegistrarAtendimento from '../../src/pages/atendimentos/RegistrarAtendimento'
import HistoricoAtendimento from '../../src/pages/atendimentos/HistoricoAtendimento'
import GraficoAtividades from '../../src/pages/graficosAtividades/GraficoAtividades'
import GraficoAtividadesUsuario from '../../src/pages/graficosAtividades/GraficoAtividadesUsuario'
import RelatorioProcessos from '../../src/pages/relatorios/RelatorioProcessos'
import PainelGerencialProcesso from '../../src/pages/processo/PainelGerencialProcesso'
import RelatorioProcessoResult from '../../src/pages/relatorios/RelatorioProcessoResult'
import Agenda from '../../src/pages/atividades/Agenda'
import ProcessosExcluidos from '../../src/pages/processo/ProcessosExcluidos'
import CentralAtividades from '../../src/pages/central/CentralAtividades'
import AlterarSenha from '../../src/pages/seguranca/AlterarSenha'
import Push from '../../src/pages/push/Push'
import Cadastrar from '../pages/seguranca/Cadastrar'

import {AuthConsumer, AuthContext} from './ProvedorAutenticacao'
import {CadastrarProcessoAutomatico} from '../pages/processo/CadastrarProcessoAutomatico';

import ResetPassword from "../pages/seguranca/ResetPassword";
import {AtividadeParaSuporte} from '../pages/suporte/AtividadeParaSuporte'
import Parecer from "../pages/parecer/Parecer";
import {DetalheParecer} from "../pages/parecer/DetalheParecer";
import {GraficoParecer} from "../pages/GraficoParecer/GraficoParecer";
import GraficoEmpresa from "../pages/graficoEmpresa/GraficoEmpresa";
import {ImprimirRelatorioParecer} from "../pages/GraficoParecer/ImprimirRelatorioParecer";
import {ConfirmarEmail} from "../pages/seguranca/ConfirmarEmail";
import PlanosEmpresa from "../pages/planos/PlanosEmpresa";
import {EmpresaConsts} from "../Consts/EmpresaConsts";
import TipoParecer from "../pages/tiposParecer/TipoParecer";
import SubObjetos from "../pages/objetosAcao/SubObjetos";
import {
    CONSULTA,
    PARECER,
    perfisAtividade,
    perfisFinanceiros,
    perfisParecer,
    perfisPessoas,
    perfisProcessos
} from "../Consts/PerfilConsts";
import Welcome from "../pages/seguranca/Welcome";
import PaymentSuccess from '../pages/planos/PaymentSuccess';

function RotaAutenticada({component: Component, isUsuarioAutenticado, ...props}) {
    const {expirou} = useContext(AuthContext);
    return (
        <Route exact {...props} render={(componentProps) => {
            if (isUsuarioAutenticado) {
                if (componentProps.location.pathname === '/pagamentoRetorno') {
                    return <Component {...componentProps} />;
                }
                if (expirou) {
                    return (
                        //redirecionar para pagina de planos
                        <PlanosEmpresa/>
                    )
                } else {
                    return (
                        <Component {...componentProps} />
                    )
                }
            } else {
                return (
                    <Redirect to={{pathname: '/login', state: {from: componentProps.location}}}/>
                )
            }
        }}/>
    )
}

function Rotas(props) {

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const isEtrium= ()=>{
        return !!baseUrl.includes("etrium.com.br") || !!baseUrl.includes("localhost");
    }
    return (
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/login" component={Login}/>
                {isEtrium() && <Route exact path="/cadastro" component={Cadastrar}/>}
                <Route exact path="/welcome" component={Welcome}/>
                <Route exact path="/redefinirSenha" component={ResetPassword}/>
                <Route exact path="/suporte" component={Suporte}/>
                <Route exact path="/confirmarEmail" component={ConfirmarEmail}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/editarEmpresa"
                          component={EditarEmpresa}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/contratarPlano"
                          component={PlanosEmpresa}/>
                <RouteHome perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/home"/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/grupoTrabalho"
                          component={GrupoTrabalho}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/subGrupos/:codigo"
                          component={SubGrupos}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/usuarios/"
                          component={Usuarios}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/cadastroUsuario/"
                          component={CadastroUsuario}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/editarUsuario/:codigo"
                          component={EditarUsuario}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/gruposPessoas"
                          component={GruposPessoas}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/tiposPagamentos"
                          component={TiposPagamentos}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/tiposCustas"
                          component={TiposCustas}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/statusProcessual"
                          component={StatusProcessual}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/objetosAcao"
                          component={ObjetosAcao}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/tiposDecisao"
                          component={TipoDecisao}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/tiposGarantias"
                          component={TipoGarantia}/>
                <RouteProcessos perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                                path="/processos"
                                component={Processos}/>
                <RouteProcessos perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                                path="/cadastrarProcessoManual"
                                component={CadastrarProcesso}/>
                <RouteProcessos perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                                path="/cadastrarProcesso"
                                component={CadastrarProcessoAutomatico}/>
                <RouteProcessos perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                                path="/fichaProcesso/:codigo"
                                component={FichaProcesso}/>
                <RoutePessoas perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/pessoas"
                              component={Pessoas}/>
                <RoutePessoas perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                              path="/consultarPessoas/:codigo"
                              component={ConsultarPessoas}/>
                <RoutePessoas perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                              path="/cadastrarPessoa"
                              component={CadastrarPessoa}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/tiposDespesasReceitas"
                          component={TiposDespesasReceitas}/>
                <RouteFinanceiros perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                                  path="/painelFinanceiro"
                                  component={PainelFinanceiro}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/relatoriosFinanceiro"
                          component={RelatorioFinanceiro}/>
                {/*<RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado}*/}
                {/*                 path="/relatorioDetalhado/:empresa/:dataInicial/:dataFinal/:tipo/:categoria/:situacao/:codigo/:classificacao/:ordem"*/}
                {/*                 component={ImprimirRelatorio}/>*/}
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/atendimentos"
                          component={Atendimentos}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/novoAtendimento"
                          component={RegistrarAtendimento}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/historicoAtendimento/:codigo/:modulo/:pessoa"
                          component={HistoricoAtendimento}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/graficosAtividades"
                          component={GraficoAtividades}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/graficosAtividadesUsuario/:usuario/:dataInicial/:dataFinal"
                          component={GraficoAtividadesUsuario}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/relatorioProcessos"
                          component={RelatorioProcessos}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/relatorioProcessoResult/:empresa/:usuario/:pessoa/:dataDecisaoInicial/:dataDecisaoFinal/:dataSentencaInicial/:dataSentencaFinal/:dataDistribuicaoInicial/:dataDistribuicaoFinal/:dataCadastroInicial/:dataCadastroFinal/:dataAlteracaoInicial/:dataAlteracaoFinal/:grupoTrabalho/:objetoAcao/:pagamento/:operadorLogicoPagamentos/:valorPagamento/:custas/:operadorLogicoCustas/:valorCustas/:garantias/:operadorLogicoGarantias/:valorGarantia/:statusProcessual/:areaAtuacao/:tipoDecisao/:excetosArquivados/:importancia/:campoNumeroProcesso/:campoNumeroCnj/:campoPasta/:campoCliente/:campoParteContraria/:campoAdvogado/:campoTipoDecisao/:campoTipoAcao/:campoAreaAtuacao/:campoDataDecisao/:campoDataCadastro/:campoDataSentenca/:campoDataAlteracao/:campoDataDistribuicao/:campoStatusProcessual/:campoGrupoTrabalho/:campoObjetoAcao/:campoGarantia/:campoValorGarantia/:campoPagamento/:campoValorPagamento/:campoCustas/:campoValorCustas/:campoPush/:campoPushEmail/:campoPushHistorico/:campoAtividade/:campoDataUltimaAtividade/:campoUltimoHistorico/:campoDataUltimoHistorico/:campoCumprimentoLiminar/:campoStatusLiminar/:liminarCumprimento/:liminarStatus/:tipoAcao"
                          component={RelatorioProcessoResult}/>
                <RouteAtividade perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/agenda"
                                component={Agenda}/>

                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/painelProcesso"
                          component={PainelGerencialProcesso}/>

                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/processosExcluidos"
                          component={ProcessosExcluidos}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/centralAtividades"
                                 component={CentralAtividades}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/push" component={Push}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/preferencias"
                                 component={AlterarSenha}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado}
                                 path="/suporte/:codigoAtividade"
                                 component={AtividadeParaSuporte}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado}
                                 path="/suporteUsuario"
                                 component={AtividadeParaSuporte}/>
                <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado} path="/pagamentoRetorno" component={PaymentSuccess}/>
                <RouteParecer perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                              path="/painelParecer/imprimir"
                              component={ImprimirRelatorioParecer}/>
                <RouteAdm perfil={props.perfil}
                          isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/tiposParecer"
                          component={TipoParecer}/>
                <RouteAdm perfil={props.perfil}
                          isUsuarioAutenticado={props.isUsuarioAutenticado}
                          path="/subObjetos/:codigo"
                          component={SubObjetos}/>
                <RouteAdm perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/painelParecer"
                          component={GraficoParecer}/>
                {props.empresa === EmpresaConsts.ETRIUM &&
                    <RotaAutenticada isUsuarioAutenticado={props.isUsuarioAutenticado}
                                     path="/empresa/graficos"
                                     component={GraficoEmpresa}/>}
                <RouteParecer perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado}
                              path="/parecerDetalhe/:parecer" component={DetalheParecer}/>
                <RouteParecer perfil={props.perfil} isUsuarioAutenticado={props.isUsuarioAutenticado} path="/parecer"
                              component={Parecer}/>
            </Switch>
        </HashRouter>
    )
}

export const AuthRoute = () => {
    return (<AuthConsumer>
        {(context) => (<Rotas perfil={context.usuarioAutenticado ? context.usuarioAutenticado.perfil.codigo : 0}
                              isUsuarioAutenticado={context.isAutenticado}
                              empresa={context.usuarioAutenticado ? context.usuarioAutenticado.empresa.cpfCnpj : null}/>)}
    </AuthConsumer>)
}

export const RouteAdm = ({component: Component, perfil, isUsuarioAutenticado, ...props}) => {
    const {expirou} = useContext(AuthContext);
    if (expirou) {
        return (
            //redirecionar para pagina de planos
            <PlanosEmpresa/>
        )
    }
    else
        {
            return (
                <Route exact {...props} render={(componentProps) => {
                    if (isUsuarioAutenticado && perfil === 1) {
                        return (
                            <Component {...componentProps} />
                        )

                    } else {
                        return (
                            <Redirect to={{pathname: '/home', state: {from: componentProps.location}}}/>
                        )
                    }
                }}/>
            )
        }

}

// Função para criar elementos de rota com base nos perfis
const createRouteElement = (perfisAutorizados) => {
    return ({component: Component, perfil, isUsuarioAutenticado, ...props}) => {
        const {expirou} = useContext(AuthContext);
        if (expirou) {
            return (
                //redirecionar para pagina de planos
                <PlanosEmpresa/>
            )
        }
        return (
            <Route exact {...props} render={(componentProps) => {
                if (isUsuarioAutenticado && perfisAutorizados.includes(perfil)) {
                    return <Component {...componentProps} />;
                } else {
                    return <Redirect to={{pathname: '/home', state: {from: componentProps.location}}}/>;
                }
            }}/>
        );
    };
};

const RouteHome =  ({perfil, isUsuarioAutenticado, ...props}) => {
    const {expirou} = useContext(AuthContext);
    if (expirou) {
        return (
            //redirecionar para pagina de planos
            <PlanosEmpresa/>
        )
    }
        return (
            <Route exact {...props} render={(componentProps) => {
                if (isUsuarioAutenticado && perfil === PARECER) {
                    return  <Parecer/>
                } else if (isUsuarioAutenticado && perfil === CONSULTA) {
                    return  <Processos/>
                }else if(isUsuarioAutenticado){
                    return  <Home/>
                }else{
                    return <Redirect to={{pathname: '/login', state: {from: componentProps.location}}}/>;
                }
            }}/>
        );
};


// Elementos de rota para diferentes listas de perfis
export const RouteFinanceiros = createRouteElement(perfisFinanceiros);
export const RouteParecer = createRouteElement(perfisParecer);
export const RouteProcessos = createRouteElement(perfisProcessos);
export const RouteAtividade = createRouteElement(perfisAtividade);
export const RoutePessoas = createRouteElement(perfisPessoas);

// export default () => {
//   return(
//       <AuthConsumer>
//         {(context) => (<Rotas isUsuarioAutenticado={context.isAutenticado} />)}
//       </AuthConsumer>
//   )
// }
