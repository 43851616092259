import { Loading } from "../../componentes/Loading";
import Navbar from "../../componentes/Navbar";
import React, { useEffect, useState, useRef } from "react";
import LocalStorageService from "../../app/service/localStorageService";
import { USUARIO_LOGADO } from "../../app/service/AuthService";
import { EmpresaConsts } from "../../Consts/EmpresaConsts";
import EmpresaPlanosService from "../../app/service/EmpresaPlanosService";
import { CardPlano } from "../../componentes/Empresa/CardPlano";
import { Dialog } from "primereact/dialog";
import PaymentPlanosService from "../../app/service/PaymentPlanosService";
import { Toast } from 'primereact/toast';
import { ProgressBar } from 'primereact/progressbar';

export default function AlterarPlanos() {

  const empresaPlanosService = new EmpresaPlanosService();
  const paymentPlanosService = new PaymentPlanosService();
  const toast = useRef(null);
  const [planos, setPlanos] = useState([]);
  const user = LocalStorageService.obterItem(USUARIO_LOGADO);
  const [recurrentStatus, setRecurrentStatus] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [countdown, setCountdown] = useState(5); // Countdown state
  const empresa = user.empresa;
  const planoAtual = empresa.plano.tipoPlano.codigo;

  useEffect(() => {
    findTipoPlanos();
    fetchRecentTransaction();
  }, []);

  async function findTipoPlanos() {
    await empresaPlanosService.getTiposPlanos().then((res) => {
      let data = res.data;
      setPlanos(data);
    });
  }

  async function fetchRecentTransaction() {
    try {
      const response = await paymentPlanosService.buscarTransacoesRecentesPorCdEmpresa(empresa.codigo);
      const recentTransactions = response.data;
      
      // Encontrar a transação mais recente
      const mostRecentTransaction = recentTransactions.reduce((mostRecent, transaction) => {
        return mostRecent.created_date > transaction.created_date ? mostRecent : transaction;
      });
  
      // Verificar o estado de recorrência da transação mais recente
      const isRecurrentActive = mostRecentTransaction.transacao.recurrent_status === 'Ativa';
  
      // Definir o estado de recorrência como verdadeiro ou falso
      setRecurrentStatus(isRecurrentActive);
    } catch (error) {
      console.error("Erro ao buscar transação mais recente:", error);
    }
  }
  
  const planoStarted = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STARTED
  );
  const planoStartedYear = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STARTED_YEAR
  );
  const planoStandart = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STANDART
  );
  const planoStandartYear = planos.find(
    (tipo) => tipo.codigo === EmpresaConsts.STANDART_YEAR
  );

  const handleAssinar = async (plano, intervalo) => {
    const cdEmpresa = user.empresa.codigo;
    const tipoPlano = plano.codigo;
    // Convertendo o valor do plano de reais para centavos
    const valorEmCentavos = plano.valor * 1;

    const pagamentoRequest = {
      SoftDescriptor: "Recorrencia",
      Cart: {
        Items: [
          {
            Name: plano.nome,
            Description: plano.descricao || "Assinatura",
            UnitPrice: valorEmCentavos,
            Quantity: 1,
            Type: "Service",
          },
        ],
      },
      Shipping: {
        Type: "WithoutShipping",
      },
      Payment: {
        BoletoDiscount: 0,
        DebitDiscount: 0,
        RecurrentPayment: {
          Interval: intervalo,
          EndDate: "",
        },
      },
    };

    try {
      const response = await new PaymentPlanosService().criarPagamento(
        pagamentoRequest,
        cdEmpresa,
        tipoPlano
      );
      const checkoutUrl = response.data.checkoutUrl;
      if (checkoutUrl) {
        setRedirectUrl(checkoutUrl);
        setShowDialog(true);
        let countdownValue = 5;
        setCountdown(countdownValue);
        const interval = setInterval(() => {
          countdownValue -= 1;
          setCountdown(countdownValue);
          if (countdownValue <= 0) {
            clearInterval(interval);
            setShowDialog(false);
            window.open(checkoutUrl, "_self");
          }
        }, 1000);
      } else {
        console.error("URL de checkout não encontrado na resposta da API.");
      }
    } catch (error) {
      console.error("Erro ao criar pagamento:", error);
    }
  };

  const handleEncerrar = async () => {
    const cdEmpresa = user.empresa.codigo;
    try {
      // Tentativa de desativar a recorrência
      await paymentPlanosService.desativarRecurrence(cdEmpresa);
      
      
      toast.current.show({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Recorrência desativada com sucesso.',
        life: 4000
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao desativar a recorrência.',
        life: 4000
      });
      console.error("Erro ao encerrar o plano:", error);
    }
  };

  return (
    <div className={"flex-row justify-content-center gap-4"}>
      <Toast ref={toast} />
      <Dialog header="Redirecionando" visible={showDialog} style={{ width: '50vw' }} modal onHide={() => setShowDialog(false)}>
        <p>Você será redirecionado para a página de pagamento em {countdown} segundos...</p>
        <ProgressBar value={(5 - countdown) * 20} />
      </Dialog>
      <CardPlano
        id="InicianteMensal"
        planoMensal={planoStarted}
        planoAnual={planoStartedYear}
        selected={planoStarted && planoStarted.codigo === planoAtual}
        onAssinar={handleAssinar}
        onEncerrar={handleEncerrar}
        recurrentStatus={recurrentStatus}
      />
      <CardPlano
        id="PadraoMensal"
        planoMensal={planoStandart}
        planoAnual={planoStandartYear}
        selected={planoStandart && planoStandart.codigo === planoAtual}
        onAssinar={handleAssinar}
        onEncerrar={handleEncerrar}
        recurrentStatus={recurrentStatus}
      />
      <CardPlano
        planoMensal={"Empresarial"}
        selected={empresa.plano.tipoPlano.nome === "Empresarial"}
      />
    </div>
  );
}
