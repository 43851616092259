import {Button} from "primereact/button";
import React, {useContext, useState} from "react";
import StatusProcessualService from "../app/service/StatusProcessualService";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {AuthContext} from "../main/ProvedorAutenticacao";
import {strings} from "../utils/strings";


export const DialogStatusProcessual = ({open, toast, close, refrashList})=>{

    const [state,setState] = useState({
            statusProcessualService : new StatusProcessualService(),
            //Cadastro
            codigo: '',
            descricao: '',
            empresa: null,
            //Progresso
            classeDiv: 'hide',

    });
    const {usuarioAutenticado} = useContext(AuthContext);


    const cadastrarStatusProcessual = async () => {
        stateChange("classeDiv", 'show');
        if(state.descricao === '') {
            toast.show({severity:'error', summary: 'Status processual', detail:'Informe a descrição do status', life: 4000});
            stateChange("classeDiv", 'hide');
            return false;
        }
        await state.statusProcessualService.cadastrarStatusProcessual({
            descricao: state.descricao,
            empresa: usuarioAutenticado.empresa
        }).then(response => {
            toast.show({severity:'success', summary: 'Status processual', detail:'Status processual cadastrado com sucesso', life: 4000});
            refrashList();
        }).catch(error => {
            stateChange("detail",error.response.data.detail);
            toast.show({severity:'error', summary: 'Status processual', detail:`${state.detail}`, life: 4000});
        });

        stateChange("classeDiv", 'hide');
        close();
    }
    function renderFooterCadastroStatus() {
        return (
            <div className="row-etrium gap-1">
                <Button label="Cadastrar"
                        className={strings.buttonPrimaryStyle}
                        onClick={cadastrarStatusProcessual}/>
            </div>
        );
    }

    function stateChange(name, value) {
        setState({
            ...state,
            [`${name}`]: value
        });
    }
    /**Cadastro de Status Processual */
    return (

    <Dialog header="Cadastro de status processual"
            visible={open}
            style={{ width: '40vw' }}
            footer={()=>renderFooterCadastroStatus()}
            onHide={close}>

        <div className="p-fluid">
            <div className="p-field">
                <label htmlFor="nomeStatus">Descrição<span className="obrigatorioAsterisco"> *</span></label>
                <InputText id="nomeStatus"
                           type="text"
                           value={state.descricao}
                           onChange={e => stateChange("descricao", e.target.value)}
                           className="p-inputtext-sm p-d-block p-mb-1"/>
            </div>
        </div>
    </Dialog>

    );
}